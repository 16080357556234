import React, { useContext, useEffect } from "react"
import Img from "gatsby-image"
import Hero from "../configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import Image from "../configurable/Image"
import Icon from "../olc-framework/Icon"
import HighlightBlock from "../configurable/HighlightBlock"
import Grid from "../configurable/Grid"
import { filterProductsBy, filterProductsByAny, markdownNodesFilter } from "~/utils"
import Col9 from "../grid/Col9"
import Col4 from "../grid/Col4"
import Col8 from "../grid/Col8"
import Col11 from "../grid/Col11"
import Col6 from "../grid/Col6"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"
import TickList from "../configurable/TickList"
import BlockCTA from "../configurable/BlockCTA"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Modal from 'react-modal';
import "./new-prods.scss"
import "./batteries-info.scss"
import Col3 from "../grid/Col3"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import Col2 from "../grid/Col2"
import FormInput from "../olc-framework/FormInput"
import FormSelect from "../olc-framework/FormSelect"
import { GetModal } from "../configurable/Modal"
import Expandable from "../configurable/Expandable"
import "./bolds.scss"

const content = {
  acdc: {
    title: 'AC or DC',
    content: (
      <>
        <div className="modal_content_container">
          <div className="modal_copy_inverter" >
            <p><strong>AC-Coupled:</strong> AC coupled battery storage systems, are an ideal solution for retrofitting to an existing Solar PV system; already in place. If you have panels in place already, this is a solution for you.</p>
            <p><strong>DC-Coupled:</strong> DC (direct current) batteries tend to be installed at the same time as solar panels as they connect directly to the panels with an Inverter. </p>
          </div>
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
          <a href='/commercial-products?filter=Panels'>View our panels</a>
        </button> */}
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },
  capacity: {
    title: 'Capacity',
    content: (
      <>
        <div className="modal_content_container">
          <div className="modal_copy_inverter" >
            <p>Usable capacity is typically the amount of energy in kWh (units) that a battery can store and be used to power your home or business. Batteries should never be drained completely and typically use a minimum of 10% to operate. Most batteries are set to 20%, however, we can change this remotely to 10% for you, and this is in line with the manufactures warranty. </p>
          </div>
          {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>Lorem ipsum dolor sit amet.</p>
          </div>
        </div> */}
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
          <a href='/commercial-products?filter=Other'>Lorem ipsum</a>
        </button> */}
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },
  installation: {
    title: 'Installation Locations',
    content: (
      <>
        <div className="modal_content_container">
          <div className="modal_copy_inverter">
            <p>
              During the full survey of your property, our surveyor will identify the best location for your battery, with it typically being installed close to your main consumer unit. The most common locations are Lofts and Garages. </p>
          </div>
        </div>
        <div className="modal_btn_container" >
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },
  cycles: {
    title: 'Battery cycles',
    content: (
      <>
        <div className="modal_content_container">
          <div className="modal_copy_inverter" >

            <p>
              A battery cycle is the number of times a battery can completely charge and discharge during its lifetime.
            </p>
          </div>
          {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>All our batteries come with 10+ years warranty </p>
          </div>
        </div> */}
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
          <a href='/commercial-products?filter=Batteries'>View our batteries</a>
        </button> */}
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },
  fit: {
    title: 'Feed-in-Tariff (FITs) and Smart Export Guarantees (SEG)',
    content: (
      <>
        <div className="modal_content_container">
          <div className="modal_copy_inverter" >

            <p>Adding a battery to your solar PV system will not affect any payments received from FITs or SEG tariffs. Adding a battery will allow you to store energy at a cheaper rate through the super off-peak night tariff!</p>
          </div>
          {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>All our inverters come with 10+ years warranty </p>
          </div>
        </div>      */}
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
          <a href='#productsGrid'>View our inverters</a>
        </button> */}
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },
  brand1: {
    title: (config) => config.title,
    content: (onViewAll, config) => (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p><strong>Manufacturing Country: </strong>{config.manufaturer}</p>
            <p >{config.content}</p>
          </div>
          {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>{config.fact}</p>
          </div>
        </div>  */}
        </div>
        <div className="modal_btn_container" >
          <button className="modal_btn" onClick={() => onViewAll(config.brand)}>
            <a href='#productsGrid'>View all brand products</a>
          </button>
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },

}

const brandModalConfigs = {
  sofar: {
    manufaturer: 'China',
    content: 'Sofarsolar is a leading solar&hybird inverter manufacturer in China. It ranges from 1KW to 255KW, which can fully meet the requirements of residential, ',
    fact: 'sofar is great',
    brand: 'Sofar',
    title: 'Sofar'
  },
  foxess: {
    manufaturer: 'China',
    content: "Engineered by some of the world's leading inverter and battery experts, our products are breaking new ground; offering customers the most advanced product features currently available, coupled with unrivalled performance and reliability.",
    fact: 'foxess is great',
    brand: 'Fox ESS',
    title: 'Fox Ess'
  },

}


const batteriesInfo = ({ markdownNodes }) => {

  // START this makes it so the customer type is set dependng where the user came from

  const { customerType, setCustomerType } = useContext(CustomerTypeContext);

  const isBusiness = React.useMemo(() => customerType === "commercial", [customerType]);
  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);
  const isSolarTogether = React.useMemo(() => customerType === "solartogether", [customerType]);

  // END this makes it so the customer type is set dependng where the user came from



  //START FILTER LOGIC

  const productsBlockRef = React.createRef() as React.RefObject<HTMLElement>


  const [hasUsedFilters, setHasUsedFilters] = React.useState(true);



  const productsWarranties = markdownNodesFilter(
    markdownNodes,
    "products_and_warranties"
  )

  const [brandFilter, setBrandFilter] = React.useState<string>('all');
  const [sizeFilter, setSizeFilter] = React.useState<string>('all');
  const [searchFilter, setSearchFilter] = React.useState<string>('');

  const batteries = filterProductsBy(productsWarranties, 'category', 'batteries');

  const [filteredProducts, setFilteredProducts] = React.useState<typeof batteries>(batteries);

  useEffect(() => {

    let filteredProducts = batteries;

    if (brandFilter !== 'all') {
      filteredProducts = filterProductsBy(filteredProducts, "brand", brandFilter);
    }

    if (sizeFilter !== 'all') {
      filteredProducts = filterProductsBy(filteredProducts, "size", sizeFilter);
    }

    if (searchFilter !== '') {
      filteredProducts = filterProductsByAny(filteredProducts, ["brand", "size", "title", "description", "keywords"], searchFilter);
    }

    if (filteredProducts !== batteries) {
      setHasUsedFilters(true);
    }

    setFilteredProducts(filteredProducts);

  }, [brandFilter, sizeFilter, searchFilter])

  const onFilterChange = (key: string, value: string) => {
    switch (key) {
      case "brand":
        setBrandFilter(value);
        break;
      case "size":
        setSizeFilter(value);
        break;
      case "search":
        setSearchFilter(value);
        break;
    }
  }

  //console.log("hasUsedFilters", hasUsedFilters)
  const showProducts = hasUsedFilters;

  //END FILTER LOGIC



  //START MODAL LOGIC

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f000';
  }

  const [modalContent, setModalContent] = React.useState(undefined);

  const [brandModalConfig, setBrandModalConfig] = React.useState(undefined);

  const closeModal = React.useCallback(() => {
    setModalContent(undefined);
  }, []);

  const onOpenModalFor = React.useCallback((name: string) => {
    setModalContent(content[name]);
  }, []);

  //END MODAL LOGIC

  const getModalContent = () => {
    if (modalContent?.content) {
      return typeof modalContent?.content === 'function' ? modalContent?.content((brand) => {
        setBrandFilter(brand);
        closeModal();
      }, brandModalConfig) : modalContent?.content
    }
    return modalContent?.content;
  }

  const getModalTitle = () => {
    if (modalContent?.title) {
      return typeof modalContent?.title === 'function' ? modalContent?.title(brandModalConfig) : modalContent?.title;
    }
    return '';
  }

  return (
    <div>
      <GetModal
        show={Boolean(modalContent)}
        onClose={closeModal}
        title={getModalTitle()}
      >
        {getModalContent()}
      </GetModal>

      <Hero imageUrl="/images/breadcrumb19.png" compact>
        <Heading level={1} underlined>
          Our Products
        </Heading>
      </Hero>
      <Block>
        <Section className="introSec" >
          <div className="container" >
            <Col11>
              <Heading level={1} >Battery Storage</Heading>
            </Col11>
            <div className="row bold-formatting">
              <Col11>
                <p >
                  Adding battery storage to your <strong>new or existing</strong> solar PV system is a fantastic way to increase the effectiveness of the energy you generate. This allows homeowners, businesses, commercial and industrial users alike to use their generated energy day or night.</p><p>
                  Here at Green Energy Together, we offer a <strong>range of different</strong> battery storage solutions allowing our team of expert designers to design a bespoke Solar PV solution to meet each of our customer's requirements.


                </p>


              </Col11>
            </div>


          </div>
        </Section>

        <Section className="schematicSec" >
          <div className="container" style={{ marginLeft: 0 }}>
            <Col11>
              <Heading level={2} underlined>What is a solar battery?</Heading>
            </Col11>
            <div className="row bold-formatting">
              <Col11>
                <p>
                  A solar battery storage unit is added to a PV system, this allows you to <strong> store the surplus</strong> energy that is generated during the day to be used in the evening or to charge your electric car overnight. By adding a solar battery system, you further <strong>decrease</strong>  your dependence on grid-supplied energy.
                </p>

                <p>Click on the blue buttons to learn more</p>
              </Col11>
            </div>
          </div>
          <div className="row" >
            <Col11>
              <div className="infocontainer hidden-xs" >
                <img src={require('../../images/batteries/battery_infographic.png')} />
                <button className="btn btn-batteries-page cycles" onClick={() => onOpenModalFor('cycles')}>Battery cycles</button>
                <button className="btn btn-batteries-page acdc" onClick={() => onOpenModalFor('acdc')}>AC or DC</button>
                <button className="btn btn-batteries-page installation" onClick={() => onOpenModalFor('installation')}>Installation</button>
                <button className="btn btn-batteries-page fit" onClick={() => onOpenModalFor('fit')}>Feed-in-Tariff (FITs) and Smart Export Guarantees (SEG)</button>
                <button className="btn btn-batteries-page capacity" onClick={() => onOpenModalFor('capacity')}>Storage Capacity</button>
              </div>

              <div className="infocontainer visible-xs" >
                <img src={require('../../images/batteries/battery_infographic.png')} />
                <button className="btn btn-batteries-page cycles" style={{ padding: '2px 5px' }} onClick={() => onOpenModalFor('cycles')}>+</button>
                <button className="btn btn-batteries-page acdc" style={{ padding: '2px 5px' }} onClick={() => onOpenModalFor('acdc')}>+</button>
                <button className="btn btn-batteries-page installation" style={{ padding: '2px 5px' }} onClick={() => onOpenModalFor('installation')}>+</button>
                <button className="btn btn-batteries-page fit" style={{ padding: '2px 5px' }} onClick={() => onOpenModalFor('fit')}>+</button>
                <button className="btn btn-batteries-page capacity" style={{ padding: '2px 5px' }} onClick={() => onOpenModalFor('capacity')}>+</button>
              </div>

            </Col11>
          </div>
        </Section>

        <Section className="inverter-tech" >
          <div className="container" >
            <Col11>
              <Heading level={2} underlined>Benefits of a solar battery</Heading>
            </Col11>

            <div className="row" style={{ paddingTop: 30 }}>

                
                <Col6>
                  <img src={require('../../images/illus/house.png')} alt="" className="house-illus" />
                
                  <Heading level={3}>For your Home</Heading>

                  <Expandable
                    readmore={
                      <>
                        <div className="container visible-xs" style={{ height: 40 }} />
                        <div className="row ">
                          <Col11>
                          <p>
                    Solar panels generate the most energy throughout the day when, traditionally, homes use the least amount of electricity. This means your solar PV system will be producing a lot of surplus energy which would otherwise be wasted. Adding a battery allows you to store this extra electricity to use as and when you want, meaning you’ll import less electricity from the grid, saving you money on your energy bills.
                  </p>
                            <TickList nolines blueticks>
                              <li>Reduce your electricity bill- Being savvy on how you use your energy could save you money. You could be charging on cheap overnight electricity and using the battery during the expensive hours if you're on a time of use tariff such as Economy 7.</li>
                              <li>Get paid for energy you don't use- If you're looking to install solar panels and a solar battery, new Smart Export Guarantee (SEG) tariffs could pay you for any excess renewable electricity you generate and export to the grid. </li>
                              <li>Reduce your carbon footprint- By installing energy storage, you're doing your bit to help towards a Net Zero future as you're generating and using renewable energy.</li>
                              <li>Go off the grid -You could choose to use the excess energy stored in your battery to power your home in the evening, making you even more independent from the grid or you could charge your electric vehicle, increasing the amount of clean energy used by your family.</li>
                            </TickList>
                          </Col11>
                        </div>
                      </>
                    }
                  />
                  
                </Col6>

                <Col6>
                  <img src={require('../../images/illus/factory.png')} alt="" className="factory-illus" />
                  <Heading level={3}>For your Business</Heading>

                  <Expandable
                    readmore={
                      <>
                        <div className="container visible-xs" style={{ height: 40 }} />
                        <div className="row ">
                          <Col11>
                          <p>
                    By installing Solar Batteries offers businesses the ability to not only store the energy generated from the solar PV system, reducing their reliance on energy from the grid, but also to control when this generated energy is used</p>
                            <TickList nolines blueticks>
                              <li>
                                Emergency Back-up power, in event of a power out or during a period of energy rationing the batteries can be used
                              </li>
                              <li>
                                Peak shaving & Load Shifting– Use energy from the batteries during times of peak demand to avoid maximum demand energy cost
                              </li>
                              <li>
                                Scalable – Solutions available for small businesses to large-scale solar farms
                              </li>
                            </TickList>
                          </Col11>
                        </div>
                      </>
                    }
                  />
                </Col6>
        
       

            </div>
          </div>
        </Section>


        <Section className="prodsSec" id="products" >
          <div className="container" >
            <Col11>
              <Heading level={2} underlined>Our storage options</Heading>
            </Col11>
            <div className="row">
              <Col11 >
                <p>
                  To help our customers find an ideal battery for their solar PV system, here is our collection of batteries with technical specifications including performance, technical characteristics and warranty. For further information, <a href="/contact-us" style={{ fontWeight: "bold", color: "#3c96c5", textDecoration: "underline" }} target="_blank">get in touch</a> with one of our advisors.</p>
              </Col11>
            </div>
            <div className=" brands row" >
              <button>
                <img src={require('../../images/brands/sofar.png')} alt="my image" onClick={() => {
                  setBrandModalConfig(brandModalConfigs.sofar);
                  onOpenModalFor('brand1');
                }} />
              </button>
              <button>
                <img src={require('../../images/brands/foxess.png')} alt="my image" onClick={() => {
                  setBrandModalConfig(brandModalConfigs.foxess);
                  onOpenModalFor('brand1');
                }} />
              </button>
            </div>
            <div className="row bold-formatting">
              <Col11 >
                <p>
                  <strong>Click on the filter</strong>  to select a category and display a selection of our products:
                </p>
              </Col11>
            </div>

          </div>

          <div className="row fieldsArea">
            <Col4>
              <FormSelect
                name="brand"
                label="Filter by brand"
                options={["all", "Sofar", "Fox ESS"]}
                value={brandFilter}
                onChange={(evt) => { onFilterChange('brand', evt.target.value); }}
              />
            </Col4>
            <Col4>
              <FormSelect
                name="size"
                label="Filter by size"
                options={["all", "2kW to 5kW", "5kW to 10kW", "10kW+"]}
                value={sizeFilter}
                onChange={(evt) => { onFilterChange('size', evt.target.value); }}
              />
            </Col4>
            <Col4>
              <FormInput
                name="keyword"
                label="Search for a product "
                placeholder="Start typing"
                value={searchFilter}
                onChange={(evt) => { onFilterChange('search', evt.currentTarget.value); }}
              />
            </Col4>
          </div>


          {showProducts && (
            <Grid id='productsGrid'>
              {filteredProducts.map(item => {
                const pwItem = item.frontmatter
                return (
                  <li key={item.fields.slug}>
                    {pwItem.image?.childImageSharp && (
                      <Img
                        fluid={pwItem.image.childImageSharp.fluid}
                        alt={pwItem.title}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "top",
                        marginTop: 24,
                      }}
                    >
                      <p className="grid-item__title">{pwItem.title}</p>
                      {pwItem.pdf?.publicURL && (
                        <>
                          <a
                            href={pwItem.pdf.publicURL}
                            target="_blank"
                            style={{
                              display: "block",
                              marginTop: 0,
                              marginLeft: 24,
                            }}
                          >
                            <div
                              className="icon__circle-wrapper"
                              style={{ fontSize: 32, color: "#70b33b" }}
                            >
                              <Icon
                                alias="pdf"
                                className="grid__icon u-styling--box-shadow"
                              />
                            </div>
                          </a>
                        </>
                      )}
                    </div>
                  </li>
                )
              })}
            </Grid>
          )}
        </Section>


      </Block>
    </div>
  )
}

export default batteriesInfo
